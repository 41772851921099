import React, { Component } from "react";
import { Link } from "react-router-dom";

// import styles....
import "../styles/useracc.css";

// import components....
// import Footer from "../components/footer";
import Input from "../components/Input";

class CreateAcc extends Component {
  // Constructor.....
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      agency: "",
      emailaddress: "",
      password: "",
      tnc: "",
    };
    this.handleSignUp = this.handleSignUp.bind(this);
  }

  // submit function....
  async handleSignUp(e) {
    e.preventDefault();
    const { firstname, lastname, agency, emailaddress, password, tnc } =
      this.state;

    try {
      if ((await tnc) !== "on") {
        window.alert(
          "You must agree to the terms and conditions before proceeding!"
        );
        return;
      }
      await fetch("http://localhost:5000/register", {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          firstname,
          lastname,
          agency,
          emailaddress,
          password,
          tnc,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "accountCreated");
        });
      console.log({
        firstname: firstname,
        lastname: lastname,
        agency: agency,
        emailaddress: emailaddress,
        passsword: password,
        tnc: tnc,
      });
      await fetch("http://localhost:5000/login", {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify({
          emailaddress, password
        })
      }).then((res) => res.json())
        .then((data) => {
          console.log(data, "userAccess");
          if (data.Status === "Ok") {
            window.alert("Account created successfully");
            window.localStorage.setItem("token", data.data);
            window.localStorage.setItem("loggedIn", true);
            window.location.href = "./";
          }
        })
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div className="craForm craForm-container">
        <div className="xyz">
          <div className="bg-image"></div>
          <div className="sup-form-container">
            <div className="form-cont">
              <h1 className="useracc-heading">Create Account</h1>
              <form className="sup-form" onSubmit={this.handleSignUp}>
                <label for="user_fname">First name</label>
                <br />
                <Input
                  id="firstname"
                  name="firstname"
                  type="text"
                  className="useracc-txt-input"
                  placeholder="Enter your first name"
                  onChange={(e) => this.setState({ firstname: e.target.value })}
                />
                <br />
                <label for="user_lname">Last name</label>
                <br />
                <Input
                  type="text"
                  id="lastname"
                  name="lastname"
                  className="useracc-txt-input"
                  placeholder="Enter your last name"
                  onChange={(e) => this.setState({ lastname: e.target.value })}
                />
                <br />
                <label for="agency_name">Real Estate Agency</label>
                <br />
                <Input
                  type="text"
                  id="agency"
                  name="agency"
                  className="useracc-txt-input"
                  placeholder="Enter your real estate agency"
                  onChange={(e) => this.setState({ agency: e.target.value })}
                />
                <br />
                <label for="user_email">Email address</label>
                <br />
                <Input
                  type="text"
                  id="emailaddress"
                  name="emailaddress"
                  className="useracc-txt-input"
                  placeholder="Enter your email address"
                  onChange={(e) =>
                    this.setState({ emailaddress: e.target.value })
                  }
                />
                <br />
                <label for="user_code">Password</label>
                <br />
                <Input
                  type="password"
                  id="password"
                  name="password"
                  className="useracc-txt-input"
                  placeholder="Create new password"
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
                <br />
                <Input
                  type="checkbox"
                  id="tnc"
                  name="tnc"
                  className="useracc-check-input"
                  value="I agree to the terms and conditions"
                  onChange={(e) => this.setState({ tnc: e.target.value })}
                />
                <label for="terms" className="label-terms">
                  {" "}
                  I have read and accept the{" "}
                  <Link to="/terms_and_condition" className="label-terms-link">
                    Terms and Condition
                  </Link>
                </label>
                <br />
                <input
                  type="submit"
                  className="useracc-submit"
                  value="Create account"
                />
              </form>
              <p className="useracc-que">
                Already have an account? <Link to="/login">Sign in</Link>
              </p>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default CreateAcc;
