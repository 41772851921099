import React, { Component } from "react";
import { Link } from "react-router-dom";

// import styles....
import "../styles/useracc.css";

// import components....
// import Footer from "../components/footer";
import Input from "../components/Input";

class Login extends Component {
  // constructor....
  constructor(props) {
    super(props);
    this.state = {
      emailaddress: "",
      password: "",
    };
    this.handleSignIn = this.handleSignIn.bind(this);
  }

  async handleSignIn(e) {
    e.preventDefault();
    const { emailaddress, password } = this.state;
    try {
      if ((await emailaddress) === "") {
        return window.alert("You have not provided your email address");
      } else if ((await password) === "") {
        return window.alert("Please input your password!");
      } else {
        await fetch("http://localhost:5000/login", {
          method: "POST",
          crossDoamin: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify({
            emailaddress, password
          })
        }).then((res) => res.json())
        .then((data) => {
          console.log(data, "userAccess");
          if (data.Status === "Ok") {
            window.alert("Login successful");
            window.localStorage.setItem("token", data.data);
            window.localStorage.setItem("loggedIn", true);
            window.location.href = './';
          }
        })
        console.log({
          emailaddress: emailaddress,
          password: password,
        });
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div className="craForm craForm-container">
        <div className="xyz">
          <div className="bg-image"></div>
          <div className="sup-form-container">
            <div className="form-cont">
              <h1 className="useracc-heading">Log in</h1>
              <form className="sup-form" onSubmit={this.handleSignIn}>
                <label htmlFor="emailaddress">Email address</label>
                <br />
                <Input
                  type="text"
                  id="emailaddress"
                  name="emailaddress"
                  className="useracc-txt-input"
                  placeholder="Enter your email address"
                  onChange={(e) =>
                    this.setState({
                      emailaddress: e.target.value,
                    })
                  }
                />
                <br />
                <label htmlFor="password">Password</label>
                <br />
                <Input
                  type="password"
                  id="password"
                  name="password"
                  className="useracc-txt-input"
                  placeholder="Enter your password"
                  onChange={(e) =>
                    this.setState({
                      password: e.target.value,
                    })
                  }
                />
                <br />
                <p className="password-reset">
                  <Link to="/reset-password" className="password-reset-link">
                    Forgot password?
                  </Link>
                </p>
                <button className="useracc-submit" type="submit">
                  Sign in
                </button>
              </form>
              <p className="useracc-que">
                New member? <Link to="/create-account">Create account</Link>
              </p>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Login;
